<template>
  <AdminPageFrame>
    <PageTitle>
      {{ $route.params.id ? trans('PAGE_TITLE:PostEdit') : trans('PAGE_TITLE:PostNew') }}
    </PageTitle>
    <t-card v-if="articleEdit" class="w-full">
      <div class="flex flex-col lg:flex-row w-full space-y-3 lg:space-y-0 mb-3">
        <FieldWrapper class="flex w-full lg:w-1/2"  :label="trans('LABEL:PostCategory')">
          <SelectField v-model="articleEdit.postCategory" :options="postCategoriesAdminData" :placeholder="''"/>
        </FieldWrapper>
        <div v-if="postCategoryIsAds" class="flex flex-row w-full lg:w-1/2 gap-x-3 lg:gap-x-6 lg:pl-6">
          <FieldWrapper class="w-1/2" :label="trans('LABEL:AdsFrom')">
            <t-datepicker
                dateFormat="Y-m-d H:i"
                userFormat="d F Y H:i"
                :weekStart="1"
                :lang="langLocale"
                :timepicker="true"
                v-model="articleEdit.adsFrom"
            />
          </FieldWrapper>
          <FieldWrapper class="w-1/2" :label="trans('LABEL:AdsTo')">
            <t-datepicker
                dateFormat="Y-m-d H:i"
                userFormat="d F Y H:i"
                :weekStart="1"
                :lang="langLocale"
                :timepicker="true"
                v-model="articleEdit.adsTo"
            />
          </FieldWrapper>
        </div>
      </div>
	    <div class="flex flex-row justify-between mb-3">
        <FieldWrapper class="flex w-1/2 lg:w-1/4 justify-start" :label="trans('LABEL:PostPublishedAt')">
          <t-datepicker
              dateFormat="Y-m-d H:i"
              userFormat="d F Y H:i"
              :weekStart="1"
              :lang="langLocale"
              :timepicker="true"
              v-model="articleEdit.publishedAtDate"
          />
        </FieldWrapper>
        <div class="flex w-1/2 items-end justify-end">
          <Button :text="trans('BUTTON:Save')" :loading="storePostLoading" @clicked="storePostAction"/>
        </div>
      </div>
	    <FieldWrapper :label="trans('LABEL:PostLanguage')">
		    <div class="flex flex-col lg:flex-row justify-evenly lg:justify-start w-full mt-2 lg:space-x-6 space-y-3 lg:space-y-0 items-start lg:items-center mb-3">
			    <label class="flex flex-row gap-3 items-center cursor-pointer hover:text-redAccentLight hover:opacity-70">
				    <t-radio v-model="languageCode" name="language_code" value="pl" />
				    <img :src="require(`@/assets/images/flags/pl.png`)" alt="flag" class="rounded-full border border-black h-6 w-6">
				    <span>{{trans("LANGUAGE:pl")}}</span>
			    </label>
			    <label class="flex flex-row gap-3 items-center cursor-pointer hover:text-redAccentLight hover:opacity-70">
				    <t-radio v-model="languageCode" name="language_code" value="en" />
				    <img :src="require(`@/assets/images/flags/en.png`)" alt="flag" class="rounded-full border border-black h-6 w-6">
				    <span>{{trans("LANGUAGE:en")}}</span>
			    </label>
			    <label class="flex flex-row gap-3 items-center cursor-pointer hover:text-redAccentLight hover:opacity-70">
				    <t-radio v-model="languageCode" name="language_code" value="uk" />
				    <img :src="require(`@/assets/images/flags/uk.png`)" alt="flag" class="rounded-full border border-black h-6 w-6">
				    <span>{{trans("LANGUAGE:uk")}}</span>
			    </label>
		    </div>
	    </FieldWrapper>
	    <FieldWrapper :label="trans('LABEL:PostLanguageIsDefault')">
		    <div class="flex flex-col lg:flex-row justify-evenly lg:justify-start w-full mt-2 lg:space-x-6 space-y-3 lg:space-y-0 items-start lg:items-center mb-3">
			    <label class="flex flex-row gap-3 items-center cursor-pointer hover:text-redAccentLight hover:opacity-70">
				    <t-radio v-model="languageCodeDefault" name="is_default" value="pl" />
				    <img :src="require(`@/assets/images/flags/pl.png`)" alt="flag" class="rounded-full border border-black h-6 w-6">
				    <span>{{trans("LANGUAGE:pl")}}</span>
			    </label>
			    <label class="flex flex-row gap-3 items-center cursor-pointer hover:text-redAccentLight hover:opacity-70">
				    <t-radio v-model="languageCodeDefault" name="is_default" value="en" />
				    <img :src="require(`@/assets/images/flags/en.png`)" alt="flag" class="rounded-full border border-black h-6 w-6">
				    <span>{{trans("LANGUAGE:en")}}</span>
			    </label>
			    <label class="flex flex-row gap-3 items-center cursor-pointer hover:text-redAccentLight hover:opacity-70">
				    <t-radio v-model="languageCodeDefault" name="is_default" value="uk" />
				    <img :src="require(`@/assets/images/flags/uk.png`)" alt="flag" class="rounded-full border border-black h-6 w-6">
				    <span>{{trans("LANGUAGE:uk")}}</span>
			    </label>
		    </div>
	    </FieldWrapper>
	    <FieldWrapper class="mb-3" :label="trans('LABEL:PostTitle')">
		    <TextField v-model="articleEdit[languageCode].title" />
	    </FieldWrapper>
	    <FieldWrapper class="mb-3" :label="trans('LABEL:PostSlug')">
		    <TextField v-model="articleEdit[languageCode].slug"/>
	    </FieldWrapper>
	    <div class="flex flex-col lg:flex-row justify-evenly lg:justify-between w-full space-y-3 lg:space-y-0 lg:space-x-6 mb-3">
        <FieldWrapper class="w-full lg:w-1/2" :label="trans('LABEL:PostFeaturedImage')">
          <div class="flex flex-row">
            <div class="pr-3" v-if="articleEdit[languageCode].imageUrl">
              <Picture classes="w-48 h-36 border border-grayed object-cover rounded" :src="articleEdit[languageCode].imageUrl" />
            </div>
            <div>
              <input v-on:change="addFeaturedPostPicture" :value="articleEdit[languageCode].imageName" type="file" />
            </div>
          </div>
        </FieldWrapper>
      </div>
      <FieldWrapper class="mb-3" :label="trans('LABEL:PostExcerpt')">
        <div class="excerpt-body">
	        <vue-editor
            class="custom-editor"
            ref="excerptEditor"
            :customModules="customModulesForEditor"
            :editorOptions="editorSettings"
            v-model="articleEdit[languageCode].excerpt"
          />
        </div>
      </FieldWrapper>
      <FieldWrapper class="mb-3" :label="trans('LABEL:PostBody')">
        <div class="post-body">
	        <vue-editor
            class="custom-editor"
            id="myQuillEditor"
            :customModules="customModulesForEditor"
            :editorOptions="editorSettings"
            v-model="articleEdit[languageCode].body"
	        />
        </div>
      </FieldWrapper>
      <div class="flex flex-row w-full justify-end">
        <Button :text="trans('BUTTON:Save')" :loading="storePostLoading" @clicked="storePostAction"/>
      </div>
    </t-card>
  </AdminPageFrame>
</template>

<script>
import AdminPageFrame from "@/views/content/admin/components/AdminPageFrame";
import {mapActions, mapGetters} from "vuex";
import TextField from "@/utils/crud/components/field-types/Text";
import PageTitle from "@/layouts/components/pages/PageTitle";
import FieldWrapper from "@/utils/crud/components/field-types/components/FieldWrapper";
import SelectField from "@/utils/crud/components/field-types/Select";
import Button from "@/utils/crud/components/Button";
import api from "@/common/services/api";
import config from "@/config"
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import {Quill, VueEditor} from "vue2-editor";
import ImageResize from '@taoqf/quill-image-resize-module';
export default {
  name: "AdminPostShow",
  components: {
    AdminPageFrame,
    Button,
    FieldWrapper,
    PageTitle,
    SelectField,
    TextField,
    quillEditor,
	  VueEditor,
  },
  data() {
    return {
      articleEdit: {
	      postCategory: null,
				pl: {

				},
	      en: {

	      },
	      uk: {

	      }
      },
	    customModulesForEditor: [
		    { alias: "imageResize", module: ImageResize }
	    ],
      isNewArticle: false,
	    editorSettings: {
		    modules: {
			    imageResize: {
            modules: [ 'Resize', 'DisplaySize' ]
          }
		    }
	    },
	    languageCode: 'pl',
	    languageCodeDefault: 'pl',
	    windowHeight: 0,
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(val) {
        this.isNewArticle = val === undefined;
        if(val) {
          this.fetchAdminPostShow(val);
        }
      }
    },
    postLoaded(val) {
      if(val) {
	      this.articleEdit = structuredClone(this.adminPostShow);
	      this.articleEdit.postCategory = this.adminPostShow.postCategory.id;
	      this.languageCode = this.adminPostShow.languageCode
	      this.languageCodeDefault = this.adminPostShow.languageCodeDefault;
      }
    },
	  postCategoriesData: {
		  immediate: true,
		  handler(val) {
			  if(val) {
				  this.$set(this.articleEdit, 'postCategory', this.postCategoriesData.find(el => el.slug === 'news').id);
			  }
		  }
	  },
	  languageCode() {
			this.$nextTick(() => {
				window.scrollTo(0, this.windowHeight);
			})
	  },
  },
  computed: {
    ...mapGetters('admin', ['adminPostShow', 'postLoaded', 'storePostLoading', 'savedPost']),
    ...mapGetters('dictionaries', ['postCategoriesAdminData']),
    ...mapGetters('lang', ['langLocale']),
	  postCategoryIsAds() {
			if (this.articleEdit.postCategory && this.postCategoriesAdminData.length > 0) {
				let category = this.postCategoriesAdminData.find(x => x.id === Number(this.articleEdit.postCategory))
				return category.slug === 'ads';
			}
	  }
  },
  methods: {
    ...mapActions('admin', ['fetchAdminPostShow', 'storeAdminPost', 'updateAdminPost']),
    ...mapActions('dictionaries', ['fetchPostCategoriesAdminData']),
	  handleScroll () {
			this.windowHeight = window.scrollY;
	  },
    addFeaturedPostPicture(event) {
      this.articleEdit[this.languageCode].featuredImageUrl = event.target.files[0];
	    this.$set(this.articleEdit[this.languageCode], 'imageUrl', URL.createObjectURL(this.articleEdit[this.languageCode].featuredImageUrl));
    },
    storePostAction() {
      let data = new FormData();
	    data.append('languageCode', this.languageCode);
	    data.append('languageCodeDefault', this.languageCodeDefault);

			if (this.articleEdit.publishedAtDate) {
				data.append('published_at', this.articleEdit.publishedAtDate);
			}

	    data.append('post_category_id', this.articleEdit.postCategory);

			if(this.articleEdit.pl.title || this.articleEdit.pl.excerpt || this.articleEdit.pl.body || this.articleEdit.pl.slug) {
				data.append('pl[title]', this.articleEdit.pl.title ?? '')
				data.append('pl[slug]', this.articleEdit.pl.slug ?? '')
				data.append('pl[excerpt]', this.articleEdit.pl.excerpt ?? '')
				data.append('pl[body]', this.articleEdit.pl.body ?? '')


				if(this.articleEdit.pl.featuredImageUrl) {
					data.append('pl[featuredImageUrl]', this.articleEdit.pl.featuredImageUrl);
				}

			}

	    if(this.articleEdit.en.title || this.articleEdit.en.excerpt || this.articleEdit.en.body || this.articleEdit.en.slug) {
		    data.append('en[title]', this.articleEdit.en.title ?? '')
		    data.append('en[slug]', this.articleEdit.en.slug ?? '')
		    data.append('en[excerpt]', this.articleEdit.en.excerpt ?? '')
		    data.append('en[body]', this.articleEdit.en.body ?? '')

		    if(this.articleEdit.en.featuredImageUrl) {
			    data.append('en[featuredImageUrl]', this.articleEdit.en.featuredImageUrl);
		    }

	    }

	    if(this.articleEdit.uk.title || this.articleEdit.uk.excerpt || this.articleEdit.uk.body || this.articleEdit.uk.slug) {
		    data.append('uk[title]', this.articleEdit.uk.title ?? '')
		    data.append('uk[slug]', this.articleEdit.uk.slug ?? '')
		    data.append('uk[excerpt]', this.articleEdit.uk.excerpt ?? '')
		    data.append('uk[body]', this.articleEdit.uk.body ?? '')

		    if(this.articleEdit.uk.featuredImageUrl) {
			    data.append('uk[featuredImageUrl]', this.articleEdit.uk.featuredImageUrl);
		    }

	    }

			if (this.postCategoryIsAds) {
				data.append('ads_from', this.articleEdit.adsFrom)
				data.append('ads_to', this.articleEdit.adsTo)
			}

      if(this.isNewArticle) {
        this.storeAdminPost(data);
      } else {
        this.updateAdminPost({data: data, postId: this.articleEdit.id});
      }
    }
  },
  mounted() {
		this.fetchPostCategoriesAdminData();

  },
	created () {
		window.addEventListener('scroll', this.handleScroll);
	},
	unmounted () {
		window.removeEventListener('scroll', this.handleScroll);
	},
}
</script>

<style lang="scss">
.post-body {
  .ql-editor {
      height: 500px;
  }
}
.excerpt-body {
  .ql-editor {
      height: 100px;
  }
}

.ql-video {
  width: 100%;
  height: 400px;
  padding: 16px 0;
}

.custom-editor .ql-toolbar {
  @apply border-l border-r border-t border-grayed rounded-t;
}

.custom-editor .ql-container {
  @apply border border-grayed rounded-b bg-grayedLight;
}

</style>